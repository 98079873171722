<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary text-primary mb-0">
          ประวัติการสั่งซื้อ
        </h3>
      </div>

      <div class="d-flex align-items-center justify-content-start mt-2">
        <h5 class="mb-0 text-primary">
          รายการสั่งซื้อทั้งหมด
        </h5>
      </div>

      <div
        v-if="HisRound.MyCart.length === 0"
        class="text-center my-4"
      >
        <img
          src="/icon/empty.png"
          alt="friend"
          height="75"
        >

        <p class="mb-0 text">
          ยังไม่มีข้อมูล
        </p>
      </div>

      <div
        v-for="(item, index) in HisRound.MyCart"
        :key="index"
        class="card-list p-light mt-50 border-0 mb-2"
      >
        <router-link
          :to="{ name: 'order-history-lot', params: { id: item.round_id } }"
        >
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <p class="mb-25 text-primary font-medium-3">
                กองสลากสุวรรณภูมิ
              </p>

              <small class="mb-0 text-primary">
                จำนวน {{ item.count }} ใบ
              </small>
              <br>

              <small class="mb-0 text-primary">
                วันที่ {{ item.roundth }}
              </small>
              <br>
            </div>

            <div>
              <p class="text-primary mb-0 mr-50 bg-txt">
                สั่งซื้อสำเร็จ
              </p>
            </div>
          </div>
        </router-link>
      </div>

      <div class="demo-spacing-0 d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="HisRound.total"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="GetHisRound()"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import moment from 'moment-timezone'
// import ThisHeader from '../components/ThisHeader.vue'
import ThisFooter from '../components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    BPagination,
    ThisFooter,
  },
  data() {
    return {
      Loadmore: false,
      currentPage: 1,
      perPage: 5,
      rows: 5,
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: 0,
      balance_withdraw: 0,
      balance_datecheck: '',
      HisRound: {
        MyCart: [],
        total: 0,
      },
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
    if (JSON.parse(localStorage.getItem('HisRound'))) {
      this.HisRound = JSON.parse(localStorage.getItem('HisRound'))
    }
  },
  mounted() {
    this.GetHisRound()
  },
  methods: {
    logout() {
      localStorage.removeItem('userData')
      this.$router.push({ name: 'home' })
      window.location.reload()
    },
    GetHisRound() {
      if (this.HisRound.total === 0) {
        this.Loadmore = true
      }

      const data = {
        page: this.currentPage,
        perpage: this.perPage,
      }
      this.$http
        .post('lotto-suvarn/MyLottoHistory/Round', data)
        .then(ress => {
          this.Loadmore = false
          // console.log(ress.data)
          this.HisRound = ress.data
          localStorage.setItem('HisRound', JSON.stringify(this.HisRound))
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped></style>
